import axios from "axios";
import { IFigmaCallbackState } from "@/typings/common";
import { logger } from "@/utils/logger";

const FIGMA_API_BASE_URL = "https://api.figma.com/v1";

const FIGMA_API_ENDPOINTS = {
  getAccessToken: () => `${FIGMA_API_BASE_URL}/oauth/token`,
  getTeamProjects: (teamId: string) =>
    `${FIGMA_API_BASE_URL}/teams/${teamId}/projects`,
  getProjectFiles: (projectId: string) =>
    `${FIGMA_API_BASE_URL}/projects/${projectId}/files`,
};

export const FIGMA_REDIRECT_URI_BACKUP = `${process.env.NEXT_PUBLIC_BASE_URL}/figma/callback`;

export const FIGMA_REDIRECT_URI_CONVERTER = `${process.env.NEXT_PUBLIC_BASE_URL}/callback`;

export const getConnectFigmaAccountUrl = (
  redirectUrl: string,
  state?: string,
) => {
  const params = new URLSearchParams({
    client_id: String(process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID),
    scope: "file_read",
    state: state ?? "abc",
    response_type: "code",
    redirect_uri: redirectUrl,
  });

  return `https://www.figma.com/oauth?${params.toString()}`;
};

export const extractFigmaTeamFromURL = (url: string): string | null => {
  const urlPattern = /(?:https?:\/\/)?(?:www\.)?figma\.com\/files\/team\/(?:\d+\/[^/]+\/)?(\d+)/;
  const match = url.match(urlPattern);

  if (!match || !match[1]) return null;

  return match[1];
};

export const getFigmaTokens = async (code: string) => {
  const { data } = await axios.post(
    FIGMA_API_ENDPOINTS.getAccessToken(),
    new URLSearchParams({
      redirect_uri: FIGMA_REDIRECT_URI_BACKUP,
      code,
      grant_type: "authorization_code",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(
          `${process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID}:${process.env.FIGMA_CLIENT_SECRET}`,
        )}`,
      },
    },
  );

  return data;
};

export const getAccessToken = async (code: string) => {
  const data = await getFigmaTokens(code);

  return data.access_token || null;
};

export const getFilesInTeam = async (accessToken: string, teamId: string) => {
  const projects = await getProjectsInTeam(accessToken, teamId);

  const filesLength = await Promise.all(
    projects.map(projectId => getFilesInProject(accessToken, projectId)),
  );

  return {
    projects: projects.length,
    files: filesLength.reduce((acc: number, curr: number) => acc + curr, 0),
  };
};

export const getProjectsInTeam = async (
  accessToken: string,
  teamId: string,
): Promise<string[]> => {
  const { data } = await axios.get(
    FIGMA_API_ENDPOINTS.getTeamProjects(teamId),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return data.projects.map((project: any) => project.id);
};

export const getFilesInProject = async (
  accessToken: string,
  projectId: string,
): Promise<number> => {
  const { data } = await axios.get(
    FIGMA_API_ENDPOINTS.getProjectFiles(projectId),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return data.files.length;
};

/**
 * Encode the given state first as json and then as base64.
 * This helps to avoid URL parsing issue when the state contains a url with query parameters.
 */
export const encodeFigmaCallbackState = (
  state: IFigmaCallbackState,
): string => {
  const jsonString = JSON.stringify(state);
  return btoa(jsonString);
};

export const decodeFigmaCallbackState = (
  encodedState: string,
): IFigmaCallbackState | undefined => {
  const jsonString = atob(encodedState);
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    logger.error(`Failed parsing Figma state. Error: ${error}`);
  }
};
